<template>
  <div class="flex pa-2 t-column m-fullwidth">
    <div class="grey-text-6-e flex-1 min-w-200 t-mb-3 m-fullwidth">
      <div
        v-if="label"
        class="fs-14 mb-2 pr-4 max-w-300 uppercase"
      >
        {{ label }}
      </div>
      <div
        v-if="subLabel"
        class="fs-12 pr-4 max-w-300"
      >
        {{ subLabel }}
      </div>
    </div>
    <div class="flex-2 m-fullwidth overflow-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionItem',
   props: {
    label: {
      type: String,
      default: '',
    },
    subLabel: {
     type: String,
      default: '',
    }
  },
}
</script>

