<template>
  <div>
    <SectionItem
      class="mb-2"
      :label="labelTop"
    >
      <div
        class="fs-14 main-text value"
        :class="{ 'link-text': entityId }"
        @click="goToEntity(entityId)"
      >
        {{ ownerName }}
      </div>
    </SectionItem>
    <SectionItem
      class="mb-2"
      label="Type"
      :sub-label="labelType"
    >
      <div>
        <TagMetaBock
          hide-second-block
          :meta-data="typeData"
        />
      </div>
    </SectionItem>
    <SectionItem
      class="mb-2"
      label="Description"
    >
      <div>
        <TagMetaBock :data-description="dataDescription" />
      </div>
    </SectionItem>
    <hr class="mt-2 mb-2 pale-grey-border">
    <SectionItem
      label="Associated tags"
      :sub-label="labelTag"
    >
      <TagMetaBock
        v-for="(tag, index) in tagMetaData"
        :key="index"
        inline-view
        :meta-data="tag"
      />
    </SectionItem>
  </div>
</template>

<script>
// Components
import SectionItem from '@/components/gl-section-item'
import TagMetaBock from '@/components/gl-tag-meta-block'
// Utils
import { goToEntity } from '@/utils/go-to-route'

export default {
  name: 'ReportSectionBody',
  components: {
    SectionItem,
    TagMetaBock,
  },

  props: {
    labelTop: {
      type: String,
      default: '',
    },
    labelType: {
      type: String,
      default: '',
    },
    labelTag: {
      type: String,
      default: '',
    },
    typeData: {
      type: [Object, String],
      default: () => {},
    },
    tagMetaData: {
      type: Array,
      default: () => [],
    },
    ownerName: {
      type: String,
      default: '',
    },
    dataDescription: {
      type: String,
      default: '',
    },
    entityId: {
      type: String,
      default: null,
    },
  },
  methods: {
    goToEntity,
  },
}
</script>
