<template>
  <div>
    <div class="report-block__header--swap py-14px pl-3">
      <div class="fs-18 bold-600 mb-1">
        Exchange Operations
      </div>
      <div class="fs-14">
        List of exchange operations (swaps) for whom requested address received funds in the requested token
      </div>
    </div>
    <div class="table-swap-wrapper">
      <div class="display-table fullwidth">
        <div class="flex report-block__sub-header--swap table-row">
          <div class="bold-600 min-w-300 cell pl-3 py-14px table-cell">
            Contribution info
          </div>
          <div class="bold-600 min-w-400 cell py-14px table-cell">
            Swap Info
          </div>
          <div class="bold-600 min-w-400 cell py-14px table-cell" />
          <div class="bold-600 min-w-200 cell py-14px table-cell" />
          <div class="bold-600 min-w-500 cell py-14px table-cell" />
        </div>

        <div
          v-for="(item, index) in paginatedItems"
          :key="index"
          class="table-row"
        >
          <div class="pr-3 py-14px pl-3 cell min-w-300 table-cell">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Amount
              </div>
              <div class="report-block__value report-block__value-amount">
                <div>{{ toComaSeparate(String(item.swapData ? `${item.swapData.amount}` : formatBtcAmount(item.amount / Math.pow(10, item.decimals), true, 'eth', item.symbol) || '--')) }}</div>
                <span
                  v-if="item.swapData && item.swapData.amountCur"
                  class="price fs-14"
                >{{ formatByPrice(item.swapData.amountCur) }}
                  <gl-icon
                    v-popover:tooltip.top="`${priceMessage('historical', item.swapData.amountCurTimestamp)}`"
                    class="price-info-icon"
                    :height="12"
                    name="info"
                    :width="12"
                  />
                </span>
              </div>
            </div>
            <div
              v-popover:tooltip.top="`${hasOnlyOneSource ? 100 : item.share * 100}%`"
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Share
              </div>
              <div class="report-block__value">
                {{ formatShare(hasOnlyOneSource ? 1 : roundShare(item.share)) }}
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-400 table-cell">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Owner
              </div>
              <div
                class="report-block__value"
                :class="{'link-text': item.entityId }"
                @click="goToEntity(item.entityId)"
              >
                {{ item.owner || '--' }}
              </div>
            </div>
            <div
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Type
              </div>
              <div>
                <GlTag
                  v-if="item.funds && item.funds.name"
                  :score="item.funds.score"
                  :tag="item.funds.name"
                />
                <div v-else>
                  --
                </div>
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-400 table-cell">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Deposit
              </div>
              <div class="report-block__value report-block__value-amount">
                <div>{{ item.swapData ? `${toComaSeparate(String(item.swapData.from.amountText))}` : '--' }}</div>
                <span
                  v-if="item.swapData && item.swapData.from.amountCur"
                  class="price fs-14"
                >{{ formatByPrice( item.swapData.from.amountCur) }}
                  <gl-icon
                    v-popover:tooltip.top="`${priceMessage('historical', item.swapData.from.amountCurTimestamp)}`"
                    class="price-info-icon"
                    :height="12"
                    name="info"
                    :width="12"
                  />
                </span>
              </div>
            </div>
            <div
              class="flex report-block__item"
            >
              <div class="report-block__label">
                Withdraw
              </div>
              <div class="report-block__value report-block__value-amount">
                <div>{{ item.swapData ? `${toComaSeparate(String(item.swapData.to.amountText))}` : '--' }}</div>
                <span
                  v-if="item.swapData && item.swapData.to.amountCur"
                  class="price fs-14"
                >{{ formatByPrice( item.swapData.to.amountCur) }}
                  <gl-icon
                    v-popover:tooltip.top="`${priceMessage('historical', item.swapData.to.amountCurTimestamp)}`"
                    class="price-info-icon"
                    :height="12"
                    name="info"
                    :width="12"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="pr-3 py-14px cell min-w-200 table-cell">
            <div class="flex report-block__item">
              <div class="report-block__label">
                Depth
              </div>
              <div class="report-block__value">
                {{ findMinMaxFields(item.depth) }}
              </div>
            </div>
          </div>
          <div class="py-14px pr-3 cell min-w-500 table-cell ">
            <div class="flex flex-end report-block__item">
              <div class="report-block__label">
                Swap TX
              </div>
              <div>
                <div
                  v-if="checkMultiple(item.tx_hash)"
                  class="report-block__value"
                >
                  Multiple
                </div>
                <div
                  v-else
                  class="flex"
                >
                  <div
                    v-popover:tooltip.top="'Open in new tab'"
                    class="link fullwidth report-block__value"
                    @click="openInNewTab(item.tx_hash)"
                  >
                    {{ item.tx_hash || '--' }}
                  </div>
                  <gl-menu-item
                    v-popover:tooltip.top="'Copy'"
                    class="sidebar__history-copy"
                    icon="copy"
                    :icon-height="24"
                    :icon-width="24"
                    @click="toClipBoard(item.tx_hash)"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex flex-end report-block__item"
            >
              <div class="report-block__label">
                Direct TX
              </div>
              <div>
                <div
                  class="flex"
                >
                  <div
                    v-popover:tooltip.top="'Open in new tab'"
                    class="link fullwidth report-block__value"
                    @click="openInNewTab(item.directTx)"
                  >
                    {{ item.directTx || '--' }}
                  </div>
                  <gl-menu-item
                    v-popover:tooltip.top="'Copy'"
                    class="sidebar__history-copy"
                    icon="copy"
                    :icon-height="24"
                    :icon-width="24"
                    @click="toClipBoard(item.directTx)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <o-pagination
      v-if="data.length && totalItems > itemsPerPage"
      class="swap-pagination m-mb-3"
      :current.sync="currentPage"
      order="right"
      :per-page="itemsPerPage"
      :total="totalItems"
    >
      <o-pagination-button
        slot="previous"
        slot-scope="props"
        :page="props.page"
      >
        <gl-menu-item
          class="change-page mr-2"
          :disabled="props.page.disabled"
          icon="left"
          :icon-height="24"
          :icon-width="24"
          label=""
          not-outline
        />
      </o-pagination-button>

      <o-pagination-button
        slot="next"
        slot-scope="props"
        :page="props.page"
      >
        <gl-menu-item
          class="change-page"
          :disabled="props.page.disabled"
          icon="right"
          :icon-height="24"
          :icon-width="24"
          label=""
          not-outline
        />
      </o-pagination-button>
    </o-pagination>
  </div>
</template>

<script>
// Components
import GlTag from '@/components/gl-tag'
import GlIcon from '@/components/gl-icon'
import GlMenuItem from "@/components/gl-menu-item.vue";
// Utils
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { trancateString } from "@/utils/text-formatter";
import { findMinMaxFields } from "@/utils/model";
import { roundShare } from "@/utils/sourcesFormatter";
import { checkMultiple } from "@/utils/report-data-formatter";
import { toComaSeparate } from "@/utils/formatNumber";
import { goToEntity } from "@/utils/go-to-route";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
// Vuex
import {mapState} from "vuex";
import { toClipBoard } from '@/utils/helpers'

export default {
  components: {
    GlTag,
    GlIcon,
    GlMenuItem
  },
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      itemsPerPage: 5,
      currentPage: 1,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData']),
    totalItems() {
      return this.data.length;
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.data.slice(startIndex, endIndex).map((el) => {
        return {
          ...el,
          decimals: el.decimals ?? this.coinData.decimals ?? 0
        }
      });
    },
  },
  methods: {
    toClipBoard,
    checkMultiple,
    formatBtcAmount,
    findMinMaxFields,
    roundShare,
    trancateString,
    toComaSeparate,
    goToEntity,
    formatByPrice,
    priceMessage,
    formatShare(share) {
      const formatted = (share * 100).toFixed(2)
      return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    },
    openInNewTab(val) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx: val, type: this.coinType } })
      window.open(href, '_blank')
    },
    openInNewTabAddress(val) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address: val, type: this.coinType } })
      window.open(href, '_blank')
    },
  },
}
</script>

<style>
.report-block__header--swap {
  background-color: var(--pale-blue);
}
.report-block__sub-header--swap div {
  background-color: var(--light-pale-blue);
}
.report-block__label {
  color: var(--dark-grey-6-e);
  //font-size: 14px;
  min-width: 90px;
}
.report-block__value {
  font-weight: 500;
  white-space: nowrap;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-block__value-amount {
  max-width: unset;
}
.report-block__item {
  padding: 5px 0;
}
.report-table__swap .o-table .o-table__th:first-child {
  border-left: 4px solid var(--soft-blue) !important;
}
.report-table__swap .o-table .o-table__td:first-child {
  border-left: 4px solid var(--soft-blue) !important;
}
.table {
  display: flex;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex: 1 0 100%;
}
.cell {
  border-bottom: 1px solid var(--light-pale-blue);
}
.table-swap-wrapper {
  width: 100%;
  overflow: auto;
  font-size: 14px;
}
.o-pag--right .o-pag__list {
  order: 0 !important;
}
</style>
