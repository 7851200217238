<template>
  <div class="progress-bar-wrapper">
    <div class="mb-2 flex justify-center">
      <VueSkeletonLoader
        v-if="calculationLoading"
        animation="wave"
        class="pie-circle-loader"
        type="circle"
      />
      <div
        v-else
        class="risk-score-value"
        :style="`color: ${findColorByTypeScore(
          showRiskScore ? totalFunds : -1,
        )}`"
      >
        {{ showRiskScore ? formatFunds(totalFunds, false) : '-' }}
      </div>
    </div>
    <div :class="{ 'progress-bar-loading': calculationLoading }">
      <progress-bar
        class="mb-2"
        :height="10"
        :percentage="totalFunds ? Number(totalFunds.toFixed(0)) : 0"
      />
      <div class="flex fs-14 grey-text-6-e">
        <div class="flex-1 m-text-center mr-3">
          Low-risk score
        </div>
        <div class="flex-1 text-center ml-3 mr-3">
          Mid-risk score
        </div>
        <div class="flex flex-end flex-1 ml-3 text-center">
          High-risk score
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { formatFunds } from '@/utils/report-data-formatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
// Libs
import 'vue-pithy-progress/lib/progress-bar.css'
import progressBar from 'vue-pithy-progress/lib/progress-bar.umd.min.js'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    progressBar,
    VueSkeletonLoader,
  },
  props: {
    totalFunds: {
      type: [Number, String],
      default: '',
    },
    hasFundsData: {
      type: Boolean,
      default: true,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showRiskScore() {
      return Boolean(this.hasFundsData && parseFloat(this.totalFunds) >= 0)
    },
  },
  methods: {
    hex2rgba,
    formatFunds,
    findColorByTypeScore,
  },
}
</script>

<style>
.progress-bar-wrapper .pie-circle-loader {
  border-radius: 50% !important;
  width: 100px !important;
  height: 100px !important;
}

.text-end-last {
  text-align-last: end;
}

.a-progress-bar-wrapper .progress-outside {
  background: linear-gradient(
    90deg,
    #70ac3f 0%,
    #ffe673 48.96%,
    #ff2a00 100%
  ) !important;
}
.progress-bar-loading .a-progress-bar-wrapper .progress-outside {
  background: linear-gradient(
    90deg,
    #e7e7e7 0%,
    #3f3f3f 48.96%,
    #000000 100%
  ) !important;
}

.a-progress-bar-wrapper .progress-inner {
  background: transparent !important;
}

.a-progress-bar-wrapper .progress-outside .progress-inner .bar-tip {
  font-size: 0;
  background: transparent !important;
}

.a-progress-bar-wrapper .progress-outside .progress-inner .bar-tip:after {
  border-top: 16px solid var(--dark-grey-6-e);
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  right: 5%;
}

.total-funds-report {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translate(-50%);
}

.risk-score-value {
  text-align: center;
  font-size: 100px;
  font-weight: 700;
}
</style>
