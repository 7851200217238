<template>
  <div>
    <p class="sidebar__analytics-label mb-3">
      Risk Score Disclaimer
    </p>
    <div class="fs-14 main-text">
      Risk score calculation is based on the analysis of the sources of funds
      received by the requested wallet address, the types of this funds, their
      tags, as well as the AML risks of all counterparties. The score is a value
      from 0 to 100, which is needed to show the degree of risk. The GL system
      considers a risk score of 0 as the lowest risk and a risk score of 100 as
      the highest risk. Learn more information
      <GlLink
        class="decoration-none"
        :url="link"
      >
        here
      </GlLink>
      about how the risk score is calculating.
    </div>
  </div>
</template>

<script>
// Components
import GlLink from '@/components/gl-link'

export default {
  name: 'RiskScoreDisclaimer',
  components: {
    GlLink,
  },
  props: {
    link: {
      type: String,
      default: "https://globalledger.io/specifics-of-a-risk-based-approach-and-gl-risk-score-calculation/",
    },
  },
}
</script>
