<template>
  <button
    v-popover:tooltip.top="
      `This wallet address is being monitored, you are able to receive fast and detailed reports as well as alerts in case of new incoming transactions, new counterparties, or AML/CTF risks identified. This report was generated by using the last ${
        coinData.family === 'eth' ? '1000' : '1000'
      } incoming transactions of the requested address.`
    "
    aria-hidden="true"
    :class="['gl-button', 'gl-button--dark', 'gl-search-box__button']"
    type="button"
  >
    Monitoring
  </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MonitoringButton',
  computed: {
    ...mapState('analytics', ['coinData']),
  },
}
</script>
