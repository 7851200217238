
<template>
  <div
    class="gl-percentage-line-wrapper"
    :class="[{ 'gl-percentage-line-wrapper--reverse': reverse }]"
  >
    <div class="gl-line-wrapper">
      <div class="gl-line-scale-wrapper">
        <div class="gl-line-scale-scale">
          %
        </div>
      </div>
      <div class="gl-line-steps-section-wrapper">
        <div
          class="gl-line-steps-wrapper"
          :style="{
            width: `calc(${percentLineWidth}%)`,
          }"
        >
          <div
            v-for="(stepItem, i) in steps"
            :key="stepItem"
            class="gl-line-step"
          >
            <div
              v-if="i === 0"
              class="gl-line-step-scale-first"
            >
              {{ maxPercent }}
            </div>
            <div class="gl-line-step-scale">
              {{ stepItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gl-percentage-horizontal-line" />
  </div>
</template>

<script>
// Utils
import { findColorByTypeScore } from '@/utils/cytoskape-ui-rules'

export default {
  props: {
    step: {
      type: [Number],
      default: null,
    },
    maxPercent: {
      type: [Number],
      default: null,
    },
    percentLineWidth: {
      type: [Number],
      default: 100,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    steps() {
      let res = []
      for (let i = 0; i <= this.maxPercent; i += this.step) {
        res.push(i)
      }
      res.pop()
      return res.reverse()
    },
  },
  methods: {
    findColorByTypeScore,
  },
}
</script>

<style>
.gl-percentage-line-wrapper {
  position: relative;
  margin: 15px 0;
}
.gl-percentage-horizontal-line {
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  border-bottom: 1px solid var(--light-grey-59);
}
.gl-line-wrapper {
  display: flex;
  padding-right: 0;
  gap: 24px;
  height: 9px;
}
.gl-line-scale-wrapper {
  position: absolute;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: var(--light-grey-59);
}

.gl-line-steps-section-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.gl-line-steps-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 101px;
}
.gl-line-step {
  position: relative;
  box-shadow: 1px 0 var(--light-grey-59);
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  color: var(--light-grey-59);
}
.gl-line-step:first-child {
  box-shadow: -1px 0 var(--light-grey-59), 1px 0px var(--light-grey-59);
}
.gl-line-step-scale,
.gl-line-scale-scale {
  position: absolute;
  transform: translate(50%, -100%);
}
.gl-line-step-scale-first {
  position: absolute;
  left: 0;
  transform: translate(-50%, -100%);
}

.gl-percentage-line-wrapper--reverse {
  direction: rtl;
}
.gl-percentage-line-wrapper--reverse .gl-line-steps-wrapper {
  margin-left: 101px;
  margin-right: 0px;
}
.gl-percentage-line-wrapper--reverse .gl-line-step {
  box-shadow: -1px 0 var(--light-grey-59);
}
.gl-percentage-line-wrapper--reverse .gl-line-step:first-child {
  box-shadow: 1px 0 var(--light-grey-59), -1px 0px var(--light-grey-59);
}

.gl-percentage-line-wrapper--reverse .gl-line-step-scale,
.gl-percentage-line-wrapper--reverse .gl-line-scale-scale {
  transform: translate(-50%, -100%);
}

.gl-percentage-line-wrapper--reverse .gl-line-step-scale-first {
  left: unset;
  right: 0;
  transform: translate(50%, -100%);
}
</style>
