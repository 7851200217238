<template>
  <a
    v-if="url"
    class="ellipsis accent-text-link"
    :href="url"
    :style="`max-width: ${maxWidth}`"
    target="_blank"
  ><slot /></a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '250px',
    },
  },
}
</script>
