<template>
  <div>
    <div id="report">
      <div class="flex mb-4 flex-wrap gap-4 t-column">
        <div class="flex-2 fullwidth">
          <Section class="mb-4">
            <template #title>
              <div class="flex flex-wrap align-center gap-3">
                <div> Address Information </div>
                <MonitoringButton v-if="addressData.isMonitoring" />
                <gl-icon
                  v-else
                  v-popover:tooltip.top="'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 1000 incoming transactions'"
                  :height="24"
                  name="def-report"
                  :width="24"
                />
              </div>
            </template>

            <template #body>
              <div
                v-if="!hasTxs && !addressDataLoading"
                class="wallet-address-wrap pa-2"
              >
                There are no transactions for this address
              </div>
              <div class="wallet-address-wrap pa-2">
                <InfoBlock
                  class="mb-4 mr-3 m-mr-0 m-mb-3"
                  font-size="14px"
                  label="Wallet address"
                  :loading="addressDataLoading"
                  :value="addressData.address"
                  word-break-value
                />
              </div>
              <div class="report-section__content-wrapper pa-2">
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Balance"
                  :loading="addressDataLoading"
                  :rate="addressData
                    && addressData.price
                    && formatByPrice(addressData.price)"
                  rate-time="current"
                  :rate-timestamp="addressData.priceTimestamp"
                  :value="
                    (addressData && addressData.formattedBalance) ||
                      (addressData.formattedBalance &&
                        toComaSeparate(
                          String(formatBtcAmount(addressData.balance, true, 3)),
                        )) ||
                      '0'
                  "
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Total sent"
                  :loading="addressDataLoading"
                  :rate="addressData
                    && addressData.amountSentPrice
                    && formatByPrice(addressData.amountSentPrice)"
                  rate-time="current"
                  :rate-timestamp="addressData.amountSentPriceTimestamp"
                  :value="addressData
                    && toComaSeparate(addressData.formattedAmountSent) || (addressData.amountSent
                      && toComaSeparate(String(formatBtcAmount(addressData.amountSent, true, 3))) || '0')"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Number of txs"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.txCount && toComaSeparate(String(addressData.txCount)) || '0'"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Total received"
                  :loading="addressDataLoading"
                  :rate="addressData
                    && addressData.amountReceivedPrice
                    && formatByPrice(addressData.amountReceivedPrice)"
                  rate-time="current"
                  :rate-timestamp="addressData.amountReceivedPriceTimestamp"
                  :value="addressData
                    && toComaSeparate(addressData.formattedAmountReceived) || (addressData.amountReceived
                      && toComaSeparate(String(formatBtcAmount(addressData.amountReceived, true, 3))) || '0')"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Total tx sent"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.txSentCount && toComaSeparate(String(addressData.txSentCount)) || '0'"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="First seen"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.firstSeen
                    && formatDate(addressData.firstSeen * 1000, 'dd.MM.yyyy HH:mm')"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Total tx received"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.txReceivedCount && toComaSeparate(String(addressData.txReceivedCount))"
                />
                <InfoBlock
                  class="mb-4 m-mb-3"
                  :inline-view="isMobile"
                  label="Last seen"
                  :loading="addressDataLoading"
                  :value="addressData
                    && addressData.lastSeen
                    && formatDate(addressData.lastSeen * 1000, 'dd.MM.yyyy HH:mm')"
                />
              </div>
              <div
                v-if="featureAccess('ALERTS')"
                class="pr-2 pl-2"
              >
                <div class="key mb-2 fs-14">
                  Profile Flags
                </div>
                <GlFlagsWrap
                  :flags-data="addressData.flagsData"
                  hide-score
                  :loading="addressDataLoading"
                  :max-count="5"
                />
              </div>
            </template>
          </Section>
          <Section
            v-if="isOwnerExist(addressData) || isClusterExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div>
                Owner Information
              </div>
            </template>
            <template #body>
              <ReportSectionBody
                v-if="isOwnerExist(addressData)"
                :data-description="addressData.description"
                :entity-id="addressData.entityId"
                label-tag="Extra context tags towards the classification of the requested address"
                label-top="Owner"
                label-type="Type of the address owner"
                :owner-name="addressData.owner"
                :tag-meta-data="addressData.meta"
                :type-data="addressData.type"
              />
              <ReportSectionBody
                v-if="isClusterExist(addressData) && !isOwnerExist(addressData)"
                :data-description="addressData.clusterData.description"
                :entity-id="addressData.clusterData.entityId"
                label-tag="Extra context tags towards the classification of the requested address"
                label-top="Owner"
                label-type="Type of the address owner"
                :owner-name="addressData.clusterData.owner"
                :tag-meta-data="addressData.clusterData.meta"
                :type-data="addressData.clusterData.type"
              />
            </template>
          </Section>
          <Section
            v-if="isOwnerExist(addressData) && isClusterExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div> Wallet Address Is Managed By </div>
            </template>
            <template #body>
              <ReportSectionBody
                :data-description="addressData.clusterData.description"
                :entity-id="addressData.clusterData.entityId"
                label-tag="Extra context tags towards the classification of the entity"
                label-top="Entity"
                label-type="Type of entity that owns requested address"
                :owner-name="addressData.clusterData.owner"
                :tag-meta-data="addressData.clusterData.meta"
                :type-data="addressData.clusterData.type"
              />
            </template>
          </Section>
          <Section
            v-if="!isClusterExist(addressData) && !isOwnerExist(addressData)"
            class="mb-4"
          >
            <template #title>
              <div> Owner Information </div>
            </template>
            <template #body>
              <div class="ml-2">
                Not identified
              </div>
            </template>
          </Section>
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section>
            <template #title>
              Risk Score
            </template>

            <template #body>
              <div class="pa-3 m-pa-2">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :has-funds-data="hasTxs"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="addressRiskySources.incoming && addressRiskySources.incoming.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{
                    sourceConfigsByDirection?.incoming?.hasOnlyOneSource ? '100%' : formatShare(percent)
                  }}</strong>
                  of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <div
                  v-if="
                    !calculationLoading && Object.keys(addressData).length > 0
                  "
                  class="mb-5"
                >
                  <p
                    v-if="
                      addressIsOwnerByHightRisk ||
                        hasDirectlyMixing ||
                        percent > 0 ||
                        addressAreUnidentified ||
                        hasTagMoreRiskPoint
                    "
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="addressIsOwnerByHightRisk"
                    class="mb-2 info-block__wrap"
                    label="Address is owned by a high risk entity"
                  />
                  <StatusBlock
                    v-if="hasDirectlyMixing"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in mixing"
                  />
                  <StatusBlock
                    v-if="percent > 0"
                    class="mb-2 info-block__wrap"
                    :label="`Address received ${
                      sourceConfigsByDirection?.incoming?.hasOnlyOneSource ? '100%' : formatShare(percent)
                    } funds from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2 info-block__wrap"
                    label="More than 75% of sources for the address are unidentified"
                  />
                  <StatusBlock
                    v-if="hasTagMoreRiskPoint"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in high risk activities"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="addressDataLoading || !hasTxs"
                  @click="explore(addressData.address)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>
      <Section v-if="activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length || calculationLoading">
        <template #title>
          Exposure
        </template>
        <template #body>
          <template v-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
            <div
              class="flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4"
            >
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSource.incoming"
                  :loading="calculationLoading"
                  title="By Type"
                  track-by-label="funds.type"
                  track-by-label-support="funds.name"
                />
              </div>
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSourceByOwner.incoming"
                  :loading="calculationLoading"
                  title="By Owner"
                  track-by-label="owner"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex fullwidth align-center space-around mb-4 mt-4">
              <div
                v-show="isDesktop"
                class="bold-600 flex-1"
              >
                Incoming Exposure
              </div>
              <div class="flex-1 flex justify-center">
                <GlSelectButton
                  :disabled="calculationLoading"
                  :options="partBarOptions"
                  :value="activePartBar"
                  @input="changeActivePartBarValue"
                />
              </div>
              <div
                v-show="isDesktop"
                class="bold-600 flex-1 text-right"
              >
                <span v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"> Outgoing Exposure</span>
              </div>
            </div>

            <div class="flex fullwidth t-flex-wrap">
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 0 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Incoming Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  class="col-6 transparent-space-right"
                  :max-percent="maxShareIncoming"
                  :percent-line-width="basisPercentGraphWidth"
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  class="col-6 transparent-space-right"
                  :data-table="activeDataSourcesIncoming"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareIncoming"
                  :per-page="barsPerPage"
                  @type-entity-item-click="typeEntityItemClick($event, 'incoming')"
                />
              </div>
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 1 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Outgoing Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  class="col-6 transparent-space-left"
                  :max-percent="maxShareOutgoing"
                  :percent-line-width="basisPercentGraphWidth"
                  reverse
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  class="col-6 transparent-space-left"
                  :data-table="activeDataSourcesOutgoing"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareOutgoing"
                  :per-page="barsPerPage"
                  reverse
                  @type-entity-item-click="typeEntityItemClick($event, 'outgoing')"
                />
              </div>
            </div>
            <div
              v-if="Number(activeDataSourcesIncoming.length) > barsPerPage || Number(activeDataSourcesOutgoing.length) > barsPerPage"
              class="flex fullwidth align-center justify-center mt-4"
            >
              <div
                class="gl-button__more-less"
                @click="toggleBarsPerPage"
              >
                {{ showFullBars ? 'Less' : 'More' }}
              </div>
            </div>
          </template>
        </template>
      </Section>
    </div>

    <div
      v-if="activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length || calculationLoading"
      class="flex fit-content justify-center mt-4 mb-4"
    >
      <GlSelectButton
        v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
        :disabled="calculationLoading"
        :options="exposurePartOptions"
        :value="activeExposurePart"
        @input="$emit('change-exposure-active-part', $event)"
      />
    </div>

    <div class="report-block-wrap">
      <div
        v-if="calculationLoading"
        class="ma-3 bold fs-18"
      >
        Loading...
      </div>
      <div v-else>
        <div
          v-if="!addressRiskySourcesResult.length && !addressUnknownSourcesResult.length &&
            !addressKnownSourcesResult.length"
          class="ma-3 bold fs-16"
        >
          <div v-if="activeExposurePart.value == 'incoming'">
            {{ Object.keys(activeTypeEntityItem).length ?
              'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' :
              'There is no sources information available.' }}
          </div>
          <div v-if="activeExposurePart.value == 'outgoing'">
            {{ Object.keys(activeTypeEntityItem).length ?
              'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' :
              'There is no destination information available.' }}
          </div>
        </div>
        <AddressRiskyTable
          v-if="addressRiskySourcesResult.length > 0"
          class="mb-5"
          :data="addressRiskySourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <AddressUnknownTable
          v-if="addressUnknownSourcesResult.length > 0"
          class="mb-5"
          :data="addressUnknownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <AddressKnownTable
          v-if="addressKnownSourcesResult.length > 0"
          :data="addressKnownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon"
import InfoBlock from '@/components/gl-info-block'
import Section from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/ReportSectionBody'
import MonitoringButton from '@/pages/report/components/MonitoringButton'
import StatusBlock from '@/pages/report/components/StatusBlock'
import GlProgressBarChart from '@/components/charts/gl-progress-bar-chart'
import AddressRiskyTable from '@/pages/report/components/AddressRiskyTable'
import AddressKnownTable from '@/pages/report/components/AddressKnownTable'
import AddressUnknownTable from '@/pages/report/components/AddressUnknownTable'
import RiskScoreDisclaimer from '@/pages/report/components/RiskScoreDisclaimer'
import GlFlagsWrap from '@/components/gl-flags-wrap'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import GlPercentageLine from '@/components/gl-percentage-line.vue'
import GlSelectButton from '@/components/gl-select-button'
// Utils
import { formatDate } from '@/utils/format-date'
import { featureAccess } from '@/utils/accesses'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatByPrice } from '@/utils/format-by-price'
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import {
  formatFunds,
  ownerLabelFormatter,
  isOwnerExist,
  isClusterExist,
  filterSourceResultByEntityType
} from '@/utils/report-data-formatter'
import { formatShare } from '@/utils/sourcesFormatter'
// Vuex
import { mapState } from "vuex";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import PieDataList from '@/pages/report/components/PieDataList.vue'
import appConfig from '@/utils/appConfig'

export default {
  components: {
    PieDataList,
    GlPercentageLine,
    GlScoringBarChart,
    GlIcon,
    InfoBlock,
    Section,
    ReportSectionBody,
    StatusBlock,
    MonitoringButton,
    AddressRiskyTable,
    AddressKnownTable,
    GlProgressBarChart,
    AddressUnknownTable,
    RiskScoreDisclaimer,
    GlFlagsWrap,
    GlSelectButton,
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({})
    },
    addressData: {
      type: Object,
      default: () => ({})
    },
    allDataSource: {
      type: Object,
      default: () => ({}),
    },
    addressRiskySources: {
      type: Object,
      default: () => ({}),
    },
    addressKnownSources: {
      type: Object,
      default: () => ({}),
    },
    addressUnknownSources: {
      type: Object,
      default: () => ({}),
    },
    allDataSourceByOwner: {
      type: Object,
      default: () => ({}),
    },
    addressDataLoading: {
      type: Boolean,
      default: false,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: '',
    },
    exposurePartOptions: {
      type: Array,
      default: () => [],
    },
    activeExposurePart: {
      type: Object,
      default: () => ({}),
    },
    activePartBar: {
      type: Object,
      default: () => ({}),
    },
    partBarOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      RISK_POINT: 55,
      step: 10,
      basisPercentGraphWidth: 50,
      activeTypeEntityItem: {},
      showFullBars: false,
      barsPerPage: 10
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    activeDataSourcesIncoming() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.incoming || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.incoming || []
      }
      return this.allDataSource
    },
    activeDataSourcesOutgoing() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.outgoing || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.outgoing || []
      }
      return this.allDataSource
    },
    maxShareIncoming() {
      if (this.activeDataSourcesIncoming && this.activeDataSourcesIncoming.length) {
        const maxValue = this.allDataSource.incoming.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesIncoming[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    maxShareOutgoing() {
      if (this.activeDataSourcesOutgoing && this.activeDataSourcesOutgoing.length) {
        const maxValue = this.allDataSource.outgoing.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesOutgoing[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    addressRiskySourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressRiskySources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      ) || []
    },
    addressKnownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressKnownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    addressUnknownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressUnknownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    addressIsOwnerByHightRisk() {
      return (
        (this.addressData?.tags?.find(tag => tag.score >= this.RISK_POINT)) ||
        (this.addressData?.clusterData?.tags?.find(
            tag => tag.score >= this.RISK_POINT,
          )) ||
        (this.addressData?.type?.score >= this.RISK_POINT) ||
        (this.addressData?.clusterData?.type?.score >= this.RISK_POINT)
      )
    },
    hasDirectlyMixing() {
      return (
        (this.addressData?.type?.name === 'mixing') ||
        (this.addressData?.clusterData?.type?.name === 'mixing') ||
        (this.addressData?.tags?.find(
            tag => tag.name === 'coin join participant',
          )) ||
        (this.addressData?.clusterData?.tags?.find(
            tag => tag.name === 'coin join participant',
          ))
      )
    },
    hasTagMoreRiskPoint() {
      return (
        (this.addressData?.tags?.find(tag => tag.score >= this.RISK_POINT)) ||
        (this.addressData?.clusterData?.tags?.find(
            tag => tag.score >= this.RISK_POINT,
          ))
      )
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources?.incoming?.reduce(
        (acc, { share }) => acc + share,
        0,
      )

      return sum * 100 >= 75
    },
    hasTxs() {
      return Boolean(this.addressData.txCount || this.allDataSource?.incoming?.length || this.allDataSourceByOwner?.incoming?.length)
    },
  },
  methods: {
    formatShare,
    featureAccess,
    formatDate,
    toComaSeparate,
    capitalizeFirstLetter,
    formatFunds,
    findColorByTypeScore,
    hex2rgba,
    formatBtcAmount,
    ownerLabelFormatter,
    isOwnerExist,
    isClusterExist,
    formatByPrice,
    filterSourceResultByEntityType,
    toggleBarsPerPage() {
      this.showFullBars = !this.showFullBars
    },
    explore(address) {
      const { href } = this.$router.resolve({
        name: 'analytics',
        query: { address, type: this.coinData.key },
      })
      window.open(href, '_blank')
    },
    changeActivePartBarValue(value) {
      this.$emit('change-active-part-bar-value', value)
    },
    typeEntityItemClick(item, activeExposurePart) {
      if (activeExposurePart === 'incoming') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[0])
      }
      if (activeExposurePart === 'outgoing') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[1])
      }
      if (this.activePartBar.value === 'type') {
        if (
          this.activeTypeEntityItem?.funds?.type === item?.funds?.type
        ) {
          this.activeTypeEntityItem = {}
        } else {

          this.activeTypeEntityItem = item
        }
      }
      if (this.activePartBar.value === 'entity') {
        if (
          this.activeTypeEntityItem?.owner === item?.owner
        ) {
          this.activeTypeEntityItem = {}
        } else {
          this.activeTypeEntityItem = item
        }
      }
    },

  },
}
</script>

<style>
.wallet-address-wrap {
  display: grid;
  grid-template-columns: auto 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}

.info-block {
  background: #cbe7ff;
  border-radius: 5px;
  padding: 10px 5px;
}

.info-block__wrap {
  padding: 0 5px;
}

.transparent-space-right,
.transparent-space-left {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
</style>
