<template>
  <div>
    <div
      id="report"
    >
      <ChooseTokenList
        :address-hash="txData.tx_hash"
        :disable="txDataLoading || calculationLoading"
        request-type="tx"
        :selected-token="selectedToken"
        :tokens-list="txData.contractsListData"
        @set-token="selectToken"
      />
      <div class="flex mb-4 flex-wrap gap-4 t-column">
        <div class="flex-2 fullwidth">
          <Section class="mb-4">
            <template #title>
              <div>
                Transaction Information
              </div>
            </template>

            <template #body>
              <div
                v-if="!txData.tx_hash && !txDataLoading"
                class="mb-4 pa-2"
              >
                There are not transaction for this hash
              </div>
              <div class="pa-2">
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Tx hash"
                  :loading="txDataLoading"
                  :value="txData.tx_hash"
                  word-break-value
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Total amount"
                  :loading="txDataLoading"
                  :rate="formatByPrice(totalAmount.amountCur)"
                  rate-time="historical"
                  :rate-timestamp="totalAmount && totalAmount.amountCurTimestamp"
                  :value="totalAmount && totalAmount.formattedTotalAmount"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Block"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.blockHeight
                    && toComaSeparate(String(txData.blockHeight))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Timestamp"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.timestamp
                    && formatDate(txData.timestamp * 1000, 'dd.MM.yyyy HH:mm')"
                />
              </div>
            </template>
          </Section>
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section class="mb-4">
            <template #title>
              <div>
                Risk Score
              </div>
            </template>

            <template #body>
              <div class="pa-3 m-pa-2">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="txRiskySources.incoming && txRiskySources.incoming.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ formatShare(percent) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <div
                  v-if="!calculationLoading"
                  class="mb-5"
                >
                  <p
                    v-if="percent * 100 > 0 || addressAreUnidentified || messages.length > 0"
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="percent * 100 > 0"
                    class="mb-2"
                    :label="`${formatShare(percent)} of funds in this transaction are from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2"
                    label="More than 75% of sources for this transaction are unidentified"
                  />
                  <StatusBlock
                    v-for="(msg, index) in messages"
                    :key="index"
                    class="mb-2"
                    :label="msg"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="txDataLoading"
                  @click="explore(txData.tx_hash)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>

      <Section v-if="calculationLoading || activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length">
        <template #title>
          Exposure
        </template>
        <template #body>
          <template v-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
            <div class="flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4">
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSource.incoming"
                  :loading="calculationLoading"
                  title="By Type"
                  track-by-label="funds.type"
                  track-by-label-support="funds.name"
                />
              </div>
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSourceByOwner.incoming"
                  :loading="calculationLoading"
                  title="By Owner"
                  track-by-label="owner"
                />
              </div>
            </div>
          </template>

          <template v-else>
            <div class="flex fullwidth align-center space-around mb-4 mt-4">
              <div
                v-show="isDesktop"
                class="bold-600 flex-1"
              >
                Incoming Exposure
              </div>
              <div class="flex-1 flex justify-center">
                <GlSelectButton
                  :disabled="calculationLoading"
                  :options="partBarOptions"
                  :value="activePartBar"
                  @input="changeActivePartBarValue"
                />
              </div>
              <div
                v-show="isDesktop"
                class="bold-600 flex-1 text-right"
              >
                <span v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"> Outgoing Exposure</span>
              </div>
            </div>

            <div class="flex fullwidth t-flex-wrap">
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 0 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Incoming Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  class="col-6 transparent-space-right"
                  :max-percent="maxShareIncoming"
                  :percent-line-width="basisPercentGraphWidth"
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  class="col-6 transparent-space-right"
                  :data-table="activeDataSourcesIncoming"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareIncoming"
                  :per-page="barsPerPage"
                  @type-entity-item-click="typeEntityItemClick($event, 'incoming')"
                />
              </div>
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 1 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Outgoing Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  class="col-6 transparent-space-left"
                  :max-percent="maxShareOutgoing"
                  :percent-line-width="basisPercentGraphWidth"
                  reverse
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  class="col-6 transparent-space-left"
                  :data-table="activeDataSourcesOutgoing"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareOutgoing"
                  :per-page="barsPerPage"
                  reverse
                  @type-entity-item-click="typeEntityItemClick($event, 'outgoing')"
                />
              </div>
            </div>
            <div
              v-if="Number(activeDataSourcesIncoming.length) > barsPerPage || Number(activeDataSourcesOutgoing.length) > barsPerPage"
              class="flex fullwidth align-center justify-center mt-4"
            >
              <div
                class="gl-button__more-less"
                @click="toggleBarsPerPage"
              >
                {{ showFullBars ? 'Less' : 'More' }}
              </div>
            </div>
          </template>
        </template>
      </Section>
      <div
        v-if="calculationLoading || activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length"
        class="flex fit-content justify-center mt-4 mb-4"
      >
        <GlSelectButton
          v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
          :disabled="calculationLoading"
          :options="exposurePartOptions"
          :value="activeExposurePart"
          @input="$emit('change-exposure-active-part', $event)"
        />
      </div>
    </div>
    <div>
      <div
        v-if="calculationLoading"
        class="ma-3 bold fs-18"
      >
        Loading...
      </div>
      <div v-else>
        <div
          v-if="!swapSourcesResult.length && !riskySourcesResult.length &&
            !unknownSourcesResult.length && !knownSourcesResult.length"
          class="ma-3 bold fs-18"
        >
          <div v-if="activeExposurePart.value == 'incoming'">
            {{ Object.keys(activeTypeEntityItem).length ?
              'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' :
              'There is no sources information available.' }}
          </div>
          <div v-if="activeExposurePart.value == 'outgoing'">
            {{ Object.keys(activeTypeEntityItem).length ?
              'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' :
              'There is no destination information available.' }}
          </div>
        </div>
        <SwapTable
          v-if="swapSourcesResult.length > 0"
          class="mb-5"
          :data="swapSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <txRiskyTable
          v-if="riskySourcesResult.length > 0"
          class="mb-5"
          :data="riskySourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <txUnknownTable
          v-if="unknownSourcesResult.length > 0"
          class="mb-5"
          :data="unknownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <txKnownTable
          v-if="knownSourcesResult.length > 0"
          :data="knownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImg from "@/components/gl-img.vue";
import InfoBlock from '@/components/gl-info-block'
import Section from '@/components/gl-section'
import SwapTable from "@/pages/report/components/SwapTable";
import StatusBlock from "@/pages/report/components/StatusBlock";
import txRiskyTable from "@/pages/report/components/TxRiskyTable";
import txKnownTable from "@/pages/report/components/TxKnownTable";
import txUnknownTable from "@/pages/report/components/TxUnknownTable";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
import ChooseTokenList from '@/pages/report/components/ChooseTokenList.vue'
import GlPercentageLine from '@/components/gl-percentage-line.vue'
import GlSelectButton from '@/components/gl-select-button.vue'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {capitalizeFirstLetter} from "@/utils/text-formatter";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds, filterSourceResultByEntityType } from "@/utils/report-data-formatter";
import { formatByPrice } from "@/utils/format-by-price";
import { formatShare } from '@/utils/sourcesFormatter'
// Vuex
import {mapState} from "vuex";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import PieDataList from '@/pages/report/components/PieDataList.vue'
import appConfig from '@/utils/appConfig'

export default {
  components: {
    PieDataList,
    GlScoringBarChart,
    GlSelectButton,
    GlPercentageLine,
    ChooseTokenList,
    GlImg,
    InfoBlock,
    Section,
    SwapTable,
    StatusBlock,
    txRiskyTable,
    txKnownTable,
    txUnknownTable,
    RiskScoreDisclaimer,
    GlProgressBarChart
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({})
    },
    txData: {
      type: Object,
      default: () => ({})
    },
    selectedToken: {
      type: Object,
      default: () => ({})
    },
    swapSources: {
      type: [Array, Object],
      default: () => []
    },
    allDataSource: {
      type: [Array, Object],
      default: () => []
    },
    txRiskySources: {
      type: [Array, Object],
      default: () => []
    },
    txKnownSources: {
      type: [Array, Object],
      default: () => []
    },
    txUnknownSources: {
      type: [Array, Object],
      default: () => []
    },
    allDataSourceByOwner: {
      type: [Array, Object],
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    },
    txDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: ''
    },
    totalAmount: {
      type: [Number, String, Object],
      default: 0
    },
    symbol: {
      type: [String],
      default: ''
    },
    exposurePartOptions: {
      type: Array,
      default: () => [],
    },
    activeExposurePart: {
      type: Object,
      default: () => ({}),
    },
    activePartBar: {
      type: Object,
      default: () => ({}),
    },
    partBarOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      RISK_POINT: 55,
      step: 10,
      basisPercentGraphWidth: 50,
      activeTypeEntityItem: {},
      showFullBars: false,
      barsPerPage: 10
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinType', 'coinData']),
    activeDataSourcesIncoming() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.incoming || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.incoming || []
      }
      return this.allDataSource
    },
    activeDataSourcesOutgoing() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.outgoing || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.outgoing || []
      }
      return this.allDataSource
    },
    maxShareIncoming() {
      if (this.activeDataSourcesIncoming && this.activeDataSourcesIncoming.length) {
        const maxValue = this.allDataSource?.incoming.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesIncoming[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    maxShareOutgoing() {
      if (this.activeDataSourcesOutgoing && this.activeDataSourcesOutgoing.length) {
        const maxValue = this.allDataSource?.outgoing.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesOutgoing[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    swapSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.swapSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      ) || []
    },
    riskySourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txRiskySources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      ) || []
    },
    knownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txKnownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    unknownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txUnknownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    addressAreUnidentified() {
      const sum = this.txUnknownSources.incoming && this.txUnknownSources.incoming.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
  },
  methods: {
    formatShare,
    formatDate,
    toComaSeparate,
    formatFunds,
    capitalizeFirstLetter,
    formatBtcAmount,
    findColorByTypeScore,
    hex2rgba,
    formatByPrice,
    filterSourceResultByEntityType,
    toggleBarsPerPage() {
      this.showFullBars = !this.showFullBars
    },
    selectToken(contract) {
      this.$emit('set-token', this.selectedToken.address === contract.address
        ? {}
        : contract )
    },
    explore(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinType } })
      window.open(href, '_blank')
    },
    changeActivePartBarValue(value) {
      this.$emit('change-active-part-bar-value', value)
    },
    typeEntityItemClick(item, activeExposurePart) {
      if (activeExposurePart === 'incoming') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[0])
      }
      if (activeExposurePart === 'outgoing') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[1])
      }
      if (this.activePartBar.value === 'type') {
        if (
          this.activeTypeEntityItem?.funds?.type === item.funds.type
        ) {
          this.activeTypeEntityItem = {}
        } else {

          this.activeTypeEntityItem = item
        }
      }
      if (this.activePartBar.value === 'entity') {
        if (
          this.activeTypeEntityItem?.owner === item.owner
        ) {
          this.activeTypeEntityItem = {}
        } else {
          this.activeTypeEntityItem = item
        }
      }
    },
  },
}
</script>

<style>
.wallet-tx-wrap {
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}
</style>
