<template>
  <div class="treeselect-wrapper max-w-300 m-fullwidth m-max-w-none">
    <treeselect
      v-if="!loading"
      v-model="value"
      class="treeselect"
      :clearable="false"
      disable-branch-nodes
      :options="options"
      @input="selectValue"
    >
      <div
        slot="value-label"
        slot-scope="{ node }"
      >
        <div class="tree-select-label">
          <GlImg
            v-if="node.raw.icon"
            class="mr-1"
            :src-val="node.raw.icon"
          />
          {{ node.raw.label }}
        </div>
      </div>
      <label
        slot="option-label"
        slot-scope="{ node }"
      >
        <div class="tree-select-label">
          <GlImg
            v-if="node.raw.icon"
            class="mr-1"
            :src-val="node.raw.icon"
          />
          {{ node.raw.label }}
        </div>
      </label>
    </treeselect>
  </div>
</template>

<script>
// Components
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import GlImg from '@/components/gl-img.vue'

// utils
import { tokensSorting } from '@/utils/cytoskape-ui-rules'

// Vuex
import { mapActions } from 'vuex'

export default {
  components: { Treeselect, GlImg },
  props: {
    address: {
      type: String,
      default: '',
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    coinData: {
      type: Object,
      default: () => ({}),
    },
    tokensList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      value: this.selectedToken?.address || '',
    }
  },
  computed: {
    data() {
      if (this.tokensList && this.tokensList.length) {
        return this.tokensList.map(e => {
          return {
            id: e.address,
            label: e.symbol,
            icon: e.icon,
          }
        })
      }
      return []
    },
    options() {
      return [...this.data]
    },
  },
  methods: {
    ...mapActions('analytics', ['getAddressTokens']),
    tokensSorting,
    selectValue(value) {
      this.$emit(
        'select',
        [...this.tokensList].find(el => {
          return el.address === value
        }),
      )
    },
  },
}
</script>

<style>
.treeselect-wrapper .tree-select-label {
  display: flex;
  align-items: center;
}
</style>
