<template>
  <div>
    <transition name="slide">
      <div
        v-show="!tokensLoading"
        class="flex"
      >
        <ChooseTokenList
          :address-hash="address"
          :disable="calculationLoading || addressDataLoading"
          request-type="address"
          :selected-token="selectedToken"
          :tokens-list="tokensList"
          @set-token="selectToken"
        />
      </div>
    </transition>
    <div
      id="report"
    >
      <div class="flex mb-4 flex-wrap gap-4 t-column">
        <div class="flex-2 fullwidth">
          <component
            :is="sectionsComponent"
            :address="address"
            :address-data="addressData"
            :address-data-loading="addressDataLoading"
            class="mb-4"
            :contract-data="contractData"
            :has-txs="hasTxs"
            :selected-token="selectedToken"
          />
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section>
            <template #title>
              Risk Score
            </template>

            <template #body>
              <div class="pa-3 m-pa-2">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :has-funds-data="hasTxs"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="addressRiskySources.incoming && addressRiskySources.incoming.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ formatShare(percent) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>
                <div
                  v-if="
                    !calculationLoading && Object.keys(addressData).length > 0
                  "
                  class="mb-5"
                >
                  <p
                    v-if="
                      addressIsOwnerByHightRisk ||
                        hasDirectlyMixing ||
                        percent * 100 > 0 ||
                        addressAreUnidentified ||
                        hasTagMoreRiskPoint
                    "
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="addressIsOwnerByHightRisk"
                    class="mb-2 info-block__wrap"
                    label="Address is owned by a high risk entity"
                  />
                  <StatusBlock
                    v-if="hasDirectlyMixing"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in mixing"
                  />
                  <StatusBlock
                    v-if="percent * 100 > 0"
                    class="mb-2 info-block__wrap"
                    :label="`Address received ${
                      sourceConfigsByDirection?.incoming?.hasOnlyOneSource ? '100%' : formatShare(percent)
                    } funds from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2 info-block__wrap"
                    label="More than 75% of sources for the address are unidentified"
                  />
                  <StatusBlock
                    v-if="hasTagMoreRiskPoint"
                    class="mb-2 info-block__wrap"
                    label="Address has directly participated in high risk activities"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="addressDataLoading"
                  @click="explore(addressData.tx_hash)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>
      <Section v-if="activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length || calculationLoading">
        <template #title>
          Exposure
        </template>
        <template #body>
          <template v-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
            <div class="flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4">
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSource.incoming"
                  :loading="calculationLoading"
                  title="By Type"
                  track-by-label="funds.type"
                  track-by-label-support="funds.name"
                />
              </div>
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSourceByOwner.incoming"
                  :loading="calculationLoading"
                  title="By Owner"
                  track-by-label="owner"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex fullwidth align-center space-around mb-4 mt-4">
              <div
                v-show="isDesktop"
                class="bold-600 flex-1"
              >
                Incoming Exposure
              </div>
              <div class="flex-1 flex justify-center">
                <GlSelectButton
                  :disabled="calculationLoading"
                  :options="partBarOptions"
                  :value="activePartBar"
                  @input="changeActivePartBarValue"
                />
              </div>
              <div
                v-show="isDesktop"
                class="bold-600 flex-1 text-right"
              >
                <span v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"> Outgoing Exposure</span>
              </div>
            </div>
            <div class="flex fullwidth t-flex-wrap">
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 0 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Incoming Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  :max-percent="maxShareIncoming"
                  :percent-line-width="basisPercentGraphWidth"
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  :data-table="activeDataSourcesIncoming"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareIncoming"
                  :per-page="barsPerPage"
                  @type-entity-item-click="typeEntityItemClick($event, 'incoming')"
                />
              </div>
              <div
                v-if="(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 1 || Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2)"
                class="fullwidth transparent-space-right"
              >
                <div
                  v-show="!isDesktop"
                  class="bold-600 py-3 text-center"
                >
                  Outgoing Exposure
                </div>
                <gl-percentage-line
                  v-show="!isMobile"
                  :max-percent="maxShareOutgoing"
                  :percent-line-width="basisPercentGraphWidth"
                  reverse
                  :step="step"
                />
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :active-type-entity-item="activeTypeEntityItem"
                  :basis-percent-graph-width="basisPercentGraphWidth"
                  :data-table="activeDataSourcesOutgoing"
                  :full="showFullBars"
                  :loading="calculationLoading"
                  :max-share="maxShareOutgoing"
                  :per-page="barsPerPage"
                  :reverse="!isMobile"
                  @type-entity-item-click="typeEntityItemClick($event, 'outgoing')"
                />
              </div>
            </div>
            <div
              v-if="Number(activeDataSourcesIncoming.length) > barsPerPage || Number(activeDataSourcesOutgoing.length) > barsPerPage"
              class="flex fullwidth align-center justify-center mt-4"
            >
              <div
                class="gl-button__more-less"
                @click="toggleBarsPerPage"
              >
                {{ showFullBars ? 'Less' : 'More' }}
              </div>
            </div>
          </template>
        </template>
      </Section>
      <div
        v-if="activeDataSourcesIncoming.length || activeDataSourcesOutgoing.length || calculationLoading"
        class="flex fit-content justify-center mt-4 mb-4"
      >
        <GlSelectButton
          v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
          :disabled="calculationLoading"
          :options="exposurePartOptions"
          :value="activeExposurePart"
          @input="$emit('change-exposure-active-part', $event)"
        />
      </div>
      <div class="report-block-wrap">
        <div
          v-if="calculationLoading"
          class="ma-3 bold fs-18"
        >
          Loading...
        </div>
        <div v-else>
          <div
            v-if="!addressSwapSourcesResult.length && !addressRiskySourcesResult.length &&
              !addressUnknownSourcesResult.length && !addressKnownSourcesResult.length"
            class="ma-3 bold fs-18"
          >
            <div v-if="activeExposurePart.value == 'incoming'">
              {{ Object.keys(activeTypeEntityItem).length ?
                'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' :
                'There is no sources information available.' }}
            </div>
            <div v-if="activeExposurePart.value == 'outgoing'">
              {{ Object.keys(activeTypeEntityItem).length ?
                'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' :
                'There is no destination information available.' }}
            </div>
          </div>
          <SwapTable
            v-if="addressSwapSourcesResult.length > 0"
            class="mb-5"
            :data="addressSwapSourcesResult"
            :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
          />
          <AddressRiskyTable
            v-if="addressRiskySourcesResult.length > 0"
            class="mb-5"
            :data="addressRiskySourcesResult"
            :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
          />
          <AddressUnknownTable
            v-if="addressUnknownSourcesResult.length > 0"
            class="mb-5"
            :data="addressUnknownSourcesResult"
            :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
          />
          <AddressKnownTable
            v-if="addressKnownSourcesResult.length > 0"
            :data="addressKnownSourcesResult"
            :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImg from "@/components/gl-img.vue";
import GlTag from "@/components/gl-tag";
import GlButton from "@/components/gl-button";
import InfoBlock from '@/components/gl-info-block';
import Section from '@/components/gl-section'
import ChooseTokenTreeSelect from '@/pages/report/components/ChooseTokenTreeSelect'
import PieDataList from'../components/PieDataList';
import SwapTable from "@/pages/report/components/SwapTable";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import AddressRiskyTable from "@/pages/report/components/AddressRiskyTable";
import AddressKnownTable from "@/pages/report/components/AddressKnownTable";
import AddressUnknownTable from "@/pages/report/components/AddressUnknownTable";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
import ChooseTokenList from '@/pages/report/components/ChooseTokenList.vue'
import GlPercentageLine from '@/components/gl-percentage-line.vue'
import GlSelectButton from '@/components/gl-select-button.vue'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {capitalizeFirstLetter, trancateString} from "@/utils/text-formatter";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds } from "@/utils/report-data-formatter";
import { ownerLabelFormatter, filterSourceResultByEntityType } from "@/utils/report-data-formatter";
import { featureAccess } from "@/utils/accesses";
import { formatShare } from '@/utils/sourcesFormatter'
import appConfig from '@/utils/appConfig'
// Vuex
import { mapState } from "vuex";

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import StatusBlock from '@/pages/report/components/StatusBlock.vue'

const EthSectionAddressInfo = () => import("@/pages/report/components/eth-section-body/EthSectionAddressInfo");
const EthSectionContractInfo = () => import("@/pages/report/components/eth-section-body/EthSectionContractInfo");
const EthSectionTokenContractInfo = () => import("@/pages/report/components/eth-section-body/EthSectionTokenContractInfo");

export default {
  components: {
    StatusBlock,
    GlScoringBarChart,
    GlSelectButton,
    GlPercentageLine,
    GlImg,
    GlTag,
    Section,
    GlButton,
    InfoBlock,
    SwapTable,
    PieDataList,
    ChooseTokenList,
    AddressRiskyTable,
    AddressKnownTable,
    GlProgressBarChart,
    AddressUnknownTable,
    RiskScoreDisclaimer,
    ChooseTokenTreeSelect
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({})
    },
    tokensLoading: {
      type: Boolean,
      default: false
    },
    contractData: {
      type: Object,
      default: () => ({})
    },
    addressData: {
      type: Object,
      default: () => ({})
    },
    swapSources: {
      type: [Array, Object],
      default: () => []
    },
    allDataSource: {
      type: [Array, Object],
      default: () => []
    },
    allDataSourceByOwner: {
      type: [Array, Object],
      default: () => []
    },
    addressRiskySources: {
      type: [Array, Object],
      default: () => []
    },
    addressKnownSources: {
      type: [Array, Object],
      default: () => []
    },
    addressUnknownSources: {
      type: [Array, Object],
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    },
    tokensList: {
      type: Array,
      default: () => []
    },
    addressDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: ''
    },
    totalAmount: {
      type: [Number, String],
      default: 0
    },
    address: {
      type: [String],
      default: ''
    },
    selectedToken: {
      type: Object,
      default: () => ({})
    },
    exposurePartOptions: {
      type: Array,
      default: () => [],
    },
    activeExposurePart: {
      type: Object,
      default: () => ({}),
    },
    activePartBar: {
      type: Object,
      default: () => ({}),
    },
    partBarOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      RISK_POINT: 55,
      openTokensModal: false,
      step: 10,
      basisPercentGraphWidth: 50,
      activeTypeEntityItem: {},
      showFullBars: false,
      barsPerPage: 10
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    activeDataSourcesIncoming() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.incoming || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.incoming || []
      }
      return this.allDataSource
    },
    activeDataSourcesOutgoing() {
      if (this.activePartBar.value === 'type') {
        return this.allDataSource?.outgoing || []
      } else if (this.activePartBar.value === 'entity') {
        return this.allDataSourceByOwner?.outgoing || []
      }
      return this.allDataSource
    },
    maxShareIncoming() {
      if (this.activeDataSourcesIncoming && this.activeDataSourcesIncoming.length) {
        const maxValue = this.allDataSource?.incoming.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesIncoming[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    maxShareOutgoing() {
      if (this.activeDataSourcesOutgoing && this.activeDataSourcesOutgoing.length) {
        const maxValue = this.allDataSource?.outgoing.reduce(
          (max, obj) => (obj.share > max ? obj.share : max),
          this.activeDataSourcesOutgoing[0].share,
        )
        return Math.ceil((maxValue * 100) / this.step) * this.step
      } else {
        return 100
      }
    },
    addressSwapSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.swapSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      ) || []
    },
    addressRiskySourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressRiskySources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      ) || []
    },
    addressKnownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressKnownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    addressUnknownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.addressUnknownSources[this.activeExposurePart.value],
        this.activeTypeEntityItem,
        this.activePartBar.value,
      )|| []
    },
    addressIsOwnerByHightRisk() {
      return (
        (this.addressData?.tags?.find(tag => tag.score >= this.RISK_POINT)) ||
        (this.addressData?.type?.score >= this.RISK_POINT)
      )
    },
    hasDirectlyMixing() {
      return (
        (this.addressData?.type?.name === 'mixing') ||
        (this.addressData?.tags?.find(
          tag => tag.name === 'coin join participant',
        ))
      )
    },
    hasTagMoreRiskPoint() {
      return this.addressData?.tags?.find(tag => tag.score >= this.RISK_POINT)
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources?.incoming?.reduce(
        (acc, { share }) => acc + share,
        0,
      )

      return sum * 100 >= 75
    },
    hasTxs() {
      return Boolean(this.addressData.txCount || this.allDataSource?.incoming?.length || this.allDataSourceByOwner?.incoming?.length)
    },
    sectionsComponent() {
      if (this.contractData.type == 'address') return EthSectionAddressInfo;
      if (this.contractData.type == 'contract') return EthSectionContractInfo;
      if (this.contractData.type == 'tokenContract') return EthSectionTokenContractInfo;
      return EthSectionAddressInfo
    }
  },
  methods: {
    formatShare,
    formatDate,
    toComaSeparate,
    formatFunds,
    trancateString,
    capitalizeFirstLetter,
    formatBtcAmount,
    findColorByTypeScore,
    ownerLabelFormatter,
    hex2rgba,
    filterSourceResultByEntityType,
    featureAccess,
    toggleBarsPerPage() {
      this.showFullBars = !this.showFullBars
    },
    changeActivePartBarValue(value) {
      this.$emit('change-active-part-bar-value', value)
    },
    selectToken(token) {
      this.$emit('set-token', token)
    },
    explore() {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address: this.address, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    typeEntityItemClick(item, activeExposurePart) {
      if (activeExposurePart === 'incoming') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[0])
      }
      if (activeExposurePart === 'outgoing') {
        this.$emit('change-exposure-active-part', this.exposurePartOptions[1])
      }
      if (this.activePartBar.value === 'type') {
        if (
          this.activeTypeEntityItem?.funds?.type === item.funds.type
        ) {
          this.activeTypeEntityItem = {}
        } else {

          this.activeTypeEntityItem = item
        }
      }
      if (this.activePartBar.value === 'entity') {
        if (
          this.activeTypeEntityItem?.owner === item.owner
        ) {
          this.activeTypeEntityItem = {}
        } else {
          this.activeTypeEntityItem = item
        }
      }
    },
  },
}
</script>

<style>
.wallet-tx-wrap {
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1450px) {
  .wrapper {
    grid-template-columns: repeat(4, 2fr);
  }
}
</style>
