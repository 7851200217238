<template>
  <gl-modal
    v-bind="$attrs"
    class="assets-token-modal"
    closable-top
    info-only
    :loading="loading || disable"
    title="Other assets"
    v-on="$listeners"
    @close="onCloseModal"
  >
    <template #content>
      <div class="pa-4">
        <gl-search-box
          v-model="search"
          button-text="Search"
          dark-clear
          :disabled="!search"
          grey
          height="38px"
          independent
          placeholder="Search"
          @clear="clearSearching"
          @input="searchToken"
          @search="searchToken"
        />
      </div>

      <div
        ref="scrollContainer"
        class="assets-token-modal__main-content"
      >
        <div class="assets-token-modal__list-wrapper">
          <div
            v-if="!tokensListResult.length && !disable"
            class="flex justify-center align-center mt-4"
          >
            No data
          </div>
          <div v-else>
            <div
              v-for="contract in tokensListResult"
              :key="contract.address"
              class="assets-token-modal__list-item"
              :class="[
                {
                  'assets-token-modal__list-item--selected':
                    selectedToken.address === contract.address,
                },
                { 'assets-token-modal__list-item--disabled': disable },
              ]"
              @click="selectToken(contract)"
            >
              <div class="flex justify-center align-center gap-1">
                <GlAvatar
                  v-if="contract.address === 'all'"
                  :avatar-list="getAvatarsList"
                  label-in-list-key="symbol"
                  :src-val="contract.icon"
                />
                <GlAvatar
                  v-else
                  :avatar-list="[contract]"
                  label-in-list-key="symbol"
                />
                <div class="bold-600">
                  {{
                    contract.symbol
                      ? trancateString(contract.symbol, 8)
                      : contract.symbol
                  }}
                </div>
              </div>

              <div class="flex justify-center align-center gap-2">
                <div>
                  <div class="fs-10 bold">
                    {{
                      toComaSeparate(
                        String(restrictNumberAfterComma(contract.balance, 8)),
                      )
                    }}
                  </div>
                  <div class="price fs-8">
                    {{ formatByPrice(contract.price) }}
                  </div>
                </div>
                <GlScoreLevelChip
                  v-if="contract.score !== undefined && contract.score !== null"
                  class="mr-1"
                  :level="contract.score"
                  score
                  small
                />
                <div>
                  <gl-icon
                    :height="16"
                    name="open"
                    :width="16"
                    @click.stop="openTokenInNewTab(contract)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlIcon from '@/components/gl-icon.vue'
import GlAvatar from '@/components/gl-avatar.vue'
import GlSearchBox from '@/components/gl-search-box'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
// Utils
import { trancateString } from '@/utils/text-formatter'
import { scrollToViewSelector } from '@/utils/scroll-to'
import { toComaSeparate, restrictNumberAfterComma } from '@/utils/formatNumber'
import { formatByPrice } from '@/utils/format-by-price'

// Vuex
import { mapState } from 'vuex'

export default {
  components: {
    GlIcon,
    GlModal,
    GlAvatar,
    GlSearchBox,
    GlScoreLevelChip,
  },
  inheritAttrs: false,
  props: {
    tokensList: {
      type: Array,
      default: () => [],
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    addressHash: {
      type: [String],
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    requestType: {
      type: String,
      default: 'address',
    },
  },
  data() {
    return {
      token: {},
      loading: false,
      search: '',
      needReloadOnCloseModal: false,
      itemsPerPage: 50,
      currentPage: 1,
      allItemsDisplayed: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    tokensListSearch() {
      if (this.search.trim() === '') {
        return this.tokensList
      }
      return this.tokensList.filter(item => {
        return item.symbol?.toLowerCase()?.includes(this.search?.toLowerCase())
      })
    },
    tokensListResult() {
      const sliceCount = this.currentPage * this.itemsPerPage
      return this.tokensListSearch.slice(0, sliceCount)
    },
    getAvatarsList() {
      return this.tokensList.filter(
        item => item.isMonitored && item.address !== 'all',
      )
    },
  },
  watch: {
    '$attrs.value': {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            if (this.$refs.scrollContainer) {
              this.$refs.scrollContainer.addEventListener(
                'scroll',
                this.onScroll,
              )
            }
          })
        } else {
          this.$refs.scrollContainer?.removeEventListener(
            'scroll',
            this.onScroll,
          )
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$refs.scrollContainer?.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    trancateString,
    scrollToViewSelector,
    toComaSeparate,
    restrictNumberAfterComma,
    formatByPrice,
    selectToken(contract) {
      if (this.disable) return
      this.$emit('select', contract)
      this.$emit('close')
    },
    openTokenInNewTab(contract) {
      if (this.disable) return

      const newUrl = this.$router.resolve({
        name: 'report',
        query: {
          [this.requestType]: this.addressHash,
          type: this.coinData.key,
          token: contract.address || '',
        },
      }).href

      window.open(newUrl, '_blank')
    },
    clearSearching() {
      this.search = ''
    },
    searchToken(val) {
      this.search = val
    },
    scrollToSelectorItem(wrap, aim, offset) {
      this.scrollToViewSelector(wrap, aim, offset)
    },
    onCloseModal() {
      if (this.needReloadOnCloseModal) {
        this.$emit('select', this.selectedToken)
      }
      this.$emit('close')
    },
    onScroll() {
      const container = this.$refs.scrollContainer
      if (
        this.tokensListResult.length < this.tokensListSearch.length &&
        container?.scrollTop + container?.clientHeight >=
          container?.scrollHeight - 100
      ) {
        this.currentPage += 1
      }
    },
  },
}
</script>

<style>
.assets-token-modal .gl-modal__title {
  font-size: 20px;
  text-transform: unset;
  margin-bottom: 0;
}

.assets-token-modal .gl-modal__container {
  padding: 0;
}

.assets-token-modal .gl-modal__title-wrapper {
  padding: 24px;
  padding-bottom: 0;
}

.assets-token-modal__tabs-wrapper {
  padding: 0px 24px;
}

.assets-token-modal__main-content {
  padding: 8px 0;
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;
}

.assets-token-modal__list-wrapper {
  display: flex;
  flex-direction: column;
}

.assets-token-modal__list-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  gap: 6px;
  color: var(--space-cadet);
  cursor: pointer;
  white-space: nowrap;
}

.assets-token-modal__list-item:hover {
  background-color: var(--cotton-grey-f-5);
}

.assets-token-modal__list-item--selected,
.assets-token-modal__list-item--selected:hover {
  background: linear-gradient(
      0deg,
      rgba(90, 120, 234, 0.15) 0%,
      rgba(90, 120, 234, 0.15) 100%
    ),
    #fff;
}

.assets-token-modal__list-item--disabled,
.assets-token-modal__list-item--disabled .gl-avatar__icon-wrapper {
  background-color: var(--pale-blue);
  cursor: not-allowed;
}
.assets-token-modal__list-item--disabled:hover {
  background-color: var(--pale-blue);
}

.assets-token-modal__main-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
</style>
