<template>
  <section class="border-radius-8 pale-grey-border">
    <div class="gl-section-title">
      <slot name="title" />
    </div>
    <div class="pa-3 m-pa-2 m-fullwidth">
      <slot name="body" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section',
}
</script>
