<template>
  <div class="mb-4 fullwidth">
    <div class="choose-token__wrapper">
      <div class="choose-token__list-wrapper">
        <div
          v-for="contract in displayTokensList"
          :key="contract.address"
          class="choose-token__chips"
          :class="[
            {
              'choose-token__chips--selected':
                selectedToken.address === contract.address,
            },
            { 'choose-token__chips--disabled': disable },
          ]"
          @click="selectToken(contract)"
        >
          <GlAvatar
            v-if="contract.address === 'all'"
            :avatar-list="getAvatarsList"
            label-in-list-key="symbol"
            :src-val="contract.icon"
          />
          <GlAvatar
            v-else
            :avatar-list="[contract]"
            label-in-list-key="symbol"
          />
          <div class="choose-token__base-width bold-600 fs-14">
            {{
              contract.symbol
                ? trancateString(contract.symbol, 7)
                : contract.symbol
            }}
          </div>
          <GlScoreLevelChip
            v-if="contract.score !== undefined && contract.score !== null"
            :level="contract.score"
            score
            small
          />
          <div
            v-if="contract.balance"
            class="fs-10 bold"
          >
            {{
              toComaSeparate(
                String(restrictNumberAfterComma(contract.balance, 8)),
              )
            }}
          </div>
          <div
            v-if="contract.price"
            class="price fs-8"
          >
            {{ formatByPrice(contract.price) }}
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="tokensList.length > 1"
          class="choose-token__assets choose-token__base-width"
          @click="openTokensModal = true"
        >
          <div class="choose-token__assets-circle">
            <gl-icon
              :height="16"
              name="more-ellipsis"
              :width="16"
            />
          </div>

          <div class="bold-600 fs-12">
            Other assets
          </div>
        </div>
      </div>
    </div>

    <AssetsTokenModal
      v-show="openTokensModal"
      v-model="openTokensModal"
      :address-hash="addressHash"
      :disable="disable"
      :request-type="requestType"
      :selected-token="selectedToken"
      :tokens-list="tokensList"
      @close="openTokensModal = false"
      @select="selectToken"
    />
    <!-- <chooseTokenModal
      v-if="openTokensModal"
      v-model="openTokensModal"
      :tokens="tokensList"
      @close="openTokensModal = false"
      @select="selectToken"
    /> -->
  </div>
</template>

<script>
// Components
import GlImg from '@/components/gl-img.vue'
import GlAvatar from '@/components/gl-avatar.vue'
import AssetsTokenModal from '@/pages/report/modals/AssetsTokenModal.vue'
import chooseTokenModal from '@/pages/report/modals/chooseTokenModal.vue'
import GlIcon from '@/components/gl-icon.vue'
import GlScoreLevelChip from '@/components/gl-score-level-chip'

// Utils
import { trancateString } from '@/utils/text-formatter'
import { getFirstLetter } from '@/utils/text-formatter'
import { toComaSeparate, restrictNumberAfterComma } from '@/utils/formatNumber'
import { formatByPrice } from '@/utils/format-by-price'

export default {
  components: {
    GlAvatar,
    GlIcon,
    GlImg,
    chooseTokenModal,
    AssetsTokenModal,
    GlScoreLevelChip,
  },
  props: {
    displayTokensCount: {
      type: Number,
      default: 10,
    },
    tokensList: {
      type: Array,
      default: () => [],
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
    addressHash: {
      type: [String],
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    requestType: {
      type: String,
      default: 'address',
    },
  },
  data() {
    return {
      openTokensModal: false,
    }
  },
  computed: {
    displayTokensList() {
      return this.tokensList
        .slice(0, this.displayTokensCount)
        .filter(token => token.symbol.trim())
    },
    getAvatarsList() {
      return this.tokensList.filter(
        item => item.isMonitored && item.address !== 'all',
      )
    },
  },
  methods: {
    trancateString,
    getFirstLetter,
    toComaSeparate,
    restrictNumberAfterComma,
    formatByPrice,
    selectToken(token) {
      if (!this.disable) {
        this.$emit('set-token', token)
      }
    },
  },
}
</script>
<style>
.choose-token__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.choose-token__list-wrapper {
  display: flex;
  overflow-x: auto;
  margin-right: 8px;
  padding-bottom: 8px;
  gap: 8px;
}
.choose-token__list-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.choose-token__chips {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 6px;
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 6px;
  color: var(--space-cadet);
  cursor: pointer;
  white-space: nowrap;
}

.choose-token__chips:hover {
  border: 1px solid var(--dark-grey);
}

.choose-token__chips--selected {
  border: 1px solid var(--accent-reflex-blue);
}

.choose-token__chips--disabled {
  border: 1px solid var(--dark-grey-6-e);
  color: var(--white);
  background-color: var(--pale-blue);
  cursor: not-allowed;
}

.choose-token__chips--disabled .gl-avatar__icon-wrapper {
  background-color: var(--pale-blue);
}
.choose-token__chips--disabled .gl-avatar__rest {
  color: var(--space-cadet);
}

.choose-token__chips-empty-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--dark-grey);
  color: var(--space-cadet);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
}

.choose-token__assets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  height: 100%;
}

.choose-token__base-width {
  min-width: 100px;
}

.choose-token__assets-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: var(--cotton-grey-f-5);
  border-radius: 50%;
}
</style>
